<template>
  <v-fade-transition>
    <div
        v-if="display"
        class="d-flex justify-center"
        style="position: absolute; width: 100%; z-index : 301 ; pointer-events: none"
        :style="positionStyle"
    >
      <ft-select
          :disabled="items.length === 0"
          :items="items"
          @input="addBlock($event)"
          @expand="$emit('dropdown' , $event)"
          :icon-color="wsATTENTION"
      >
        <v-btn
            @click="addBlock(null , true)"
            :color="wsATTENTION"
            class="mt-n4"
            style="z-index : 999; background-color: white;pointer-events: auto"
            icon
            :small="small"

        >
          <v-icon :large="!small">mdi-plus-circle</v-icon>
        </v-btn>
      </ft-select>

    </div>
  </v-fade-transition>
</template>

<script>
export default {
  name: "editorAddBlockButton",
  props : {
    display : {
      type : Boolean,
      default : false
    },
    items : {
      type : Array,
      default() {
        return []
      }
    },
    small : {
      type : Boolean,
      default : false
    },
    bottom : {
      type : [String,Number],
    },
    top : {
      type : [String,Number],
    },

  },
  computed: {
    positionStyle() {
      let style = ''

      if ( this.bottom) {
        style += `bottom : ${this.bottom}px;`
      }

      if ( this.top) {
        style += `top : ${this.bottom}px;`
      }

      return style
    }
  },
  methods : {
    addBlock(value , fromButton) {

      if (this.items.length > 0 && fromButton ) {
        return
      }
      this.$emit('click' , value)
    }
  }
}
</script>

<style scoped>

</style>