<template>
  <textarea
      v-model="element.content"
      @input="adjustTextareaHeight"
      @change="editText"
      @keydown.enter="editText"
      ref="text"
      type="text editorStyle"
      :placeholder="$t('EnterText')"
      :style="textFieldStyle"
      style="width: 100%; resize: none; outline: none;"
      rows="1"
  />

</template>

<script>
import finemailer from "@/modules/finemailer/mixins/finemailer";

export default {
  name: "emailTextHeadingEditor",
  mixins : [finemailer],
  props : {
    element : {
      type : Object,
      default() {
        return {
          config : {}
        }
      }
    }
  },
  data() {
    return {
      width : 600
    }
  },
  computed : {
    widthPixels() {
      return this.width + 'px'
    },
    fontStyle() {
      return this.element.config.font_style
    },
    textFieldStyle() {
      let style = ''
      style += `color : ${this.EMAIL_CONFIG_COLOR(this.element.config.font_style)};`
      style += `font-size : ${(parseInt(this.GET_EMAIL_FONT_SIZE(this.element.config.font_style)) || 14) + 'px'};`
      style += `text-align : ${this.element.config.align || 'left'};`
      style += `font-weight : ${ ['p','h6'].includes(this.element.config.font_style)  ? this.EMAIL_CONFIG.font_weight_paragraph : this.EMAIL_CONFIG.font_weight_heading};`
      style += `line-height : ${ ['p','h6'].includes(this.element.config.font_style)  ? this.EMAIL_CONFIG.font_line_height_paragraph : this.EMAIL_CONFIG.font_line_height_heading}%;`


      style += `text-transform : ${ ['p','h6'].includes(this.element.config.font_style)  ? this.EMAIL_CONFIG.font_case_paragraph : this.EMAIL_CONFIG.font_case_heading};`

      style += `font-family : ${ this.EMAIL_CONFIG[ ['p','h6'].includes(this.element.config.font_style)
          ? 'font_family_paragraph' : 'font_family_heading'] || 'Courier'};`

      return style
    },
    config() {
      return this.element.config
    }
  },
  watch : {
    fontStyle() {
      setTimeout(this.adjustTextareaHeight , 10)
    },
    EMAIL_CONFIG : {
      handler() {
        setTimeout(this.adjustTextareaHeight , 10)
      },
      deep : true
    }
  },
  methods : {
    adjustTextareaHeight() {
      const input = this.$refs.text;
      input.style.height = "auto";
      if ( this.element.content ) {
        input.style.height = input.scrollHeight + "px";
      }
    },
    editText($event) {
      this.$store.state.finemailer.selectedElement.content = $event.target.value
      this.$store.state.finemailer.elementEditTrigger++
    },
    elementWidth() {
      const element = document.getElementById(`email_element_${this.element.uuid}`)
      const rect = element.getBoundingClientRect()
      this.width = rect.width
    },

  },
  mounted() {
    this.adjustTextareaHeight()
    setTimeout(this.adjustTextareaHeight , 1)
  }
}
</script>

<style scoped>
.v-input__slot {
  padding : 0 !important
}
</style>