<template>
  <v-sheet
      :style="`border : 1px solid ${wsBACKGROUND} !important;` + customStyle"
      class="d-flex overflow-hidden wsRoundedLight"
      :class="[{'flex-column' : !horizontal}]"

  >
    <template v-for="(item,index) in items">


        <ft-select

            @input="childAction(item , $event)"
            v-if="!item.divider" :key="index + 'item'"
            :items="item.children || []"
            :disabled="!item.children"
            min-width="0"
            delay-close
            nudge-left="0"
            :value="item.currentValue || null"
        >
          <v-hover #default="{hover}">
          <v-sheet
              @click="action(item)"
              :color="getColor(hover,item)"
              :class="[{pointer : !item.disabled}]"
              class="wsRoundedLight d-flex align-center justify-center"
              height="40"
              :width="!item.text ? 40 : null"

          >
            <v-icon v-if="!item.text" class="mx-auto" :size="item.size || null" :color="!item.disabled ? wsACCENT : wsBACKGROUND">{{  item.icon  }}</v-icon>
            <div class="d-flex align-center text-no-wrap  px-2" v-else>
              <h5 class="wsACCENT">{{ item.currentValue ? getItemCurrentValueText(item) : item.text  }}</h5>
              <v-icon v-if="item.children && item.children.length > 0" :color="wsACCENT">mdi-menu-down</v-icon>
            </div>
          </v-sheet>
          </v-hover>
        </ft-select>



      <div v-else-if="item.divider" :key="index + 'divider'">
        <v-divider :style="`border-color: ${wsBACKGROUND}`" vertical></v-divider>
      </div>

    </template>


  </v-sheet>
</template>

<script>
export default {
  name: "emailEditSideMenu",
  props : {
    items : {
      type : Array,
      default() { return {} }
    },
    customStyle : {
      type : String,
      default : ''
    },
    horizontal : {
      type : Boolean,
      default : false
    }
  },
  methods : {
    getItemCurrentValueText(item) {
      let element = item.children.find(el => el.value === item.currentValue)

      return element ? element.text : null
    },
    getColor(hover,item) {
      if ( item.disabled ) {
        return null
      }
      return hover ? this.wsLIGHTCARD : null
    },
    childAction(item, childValue) {
      if ( item.disabled || this.LOADING) {
        return
      }
      let value = `${item.value}@${childValue}`
      this.$emit('action' , value)
    },
    action(item) {
      if ( item.children && item.children.length > 0) {
        return
      }
      if ( item.disabled || this.LOADING) {
        return
      }
      this.$emit('action' , item.value)
    }
  }
}
</script>

<style scoped>

</style>